import $ from 'jquery';

export default class MainMenu {
  init() {
    $('.top-menu li:has(.sub-menu)').addClass("has_submenu");
    $('.toggle_submenu').click(function() {
      $(this).siblings('ul').slideToggle().parent('li').toggleClass('opened');
    });

    $('#toggle_menu, #menu_close').click(function() {
    	$('#mobile_top_menu_wrapper').toggleClass('shown');
    });
  }
}
